import { CountryCode } from "./api/CountryCode";

// used for variable placeholders, and to populate the country select dropdown
type CountryDetail = {
  priority?: number; // higher numbers are higher in the list
  name: string;
  prefix: string;
  placeholder: string;
  smsEnabled?: boolean;
  allowMatchByIP?: boolean; // used when matching against cf headers
};

// returns the placeholder for a given country code
export const getPlaceholder = (code: CountryCode) =>
  countryDetails[code].placeholder;

// Note: there are helpers to pull out countryDetails based on priority field at the bottom of this file!

export const countryDetails: {
  [key in CountryCode]: CountryDetail;
} = {
  [CountryCode.AF]: {
    name: "Afghanistan",
    prefix: "93",
    placeholder: "701234567",
  },
  [CountryCode.AL]: {
    name: "Albania",
    prefix: "355",
    placeholder: "0691234567",
  },
  [CountryCode.DZ]: {
    name: "Algeria",
    prefix: "213",
    placeholder: "0550123456",
  },
  [CountryCode.AS]: {
    name: "American Samoa",
    prefix: "1-684",
    placeholder: "7331234",
  },
  [CountryCode.AD]: { name: "Andorra", prefix: "376", placeholder: "312345" },
  [CountryCode.AO]: { name: "Angola", prefix: "244", placeholder: "923123456" },
  [CountryCode.AI]: {
    name: "Anguilla",
    prefix: "1-264",
    placeholder: "2351234",
  },
  [CountryCode.AQ]: { name: "Antarctica", prefix: "672", placeholder: "10" },
  [CountryCode.AG]: {
    name: "Antigua and Barbuda",
    prefix: "1-268",
    placeholder: "7801234",
  },
  [CountryCode.AR]: {
    name: "Argentina",
    prefix: "54",
    placeholder: "91123456789",
  },
  [CountryCode.AM]: { name: "Armenia", prefix: "374", placeholder: "77123456" },
  [CountryCode.AW]: { name: "Aruba", prefix: "297", placeholder: "5601234" },
  [CountryCode.AU]: {
    name: "Australia",
    prefix: "61",
    placeholder: "0412345678",
  },
  [CountryCode.AT]: {
    name: "Austria",
    prefix: "43",
    placeholder: "06601234567",
  },
  [CountryCode.AZ]: {
    name: "Azerbaijan",
    prefix: "994",
    placeholder: "401234567",
  },
  [CountryCode.BS]: {
    name: "Bahamas",
    prefix: "1-242",
    placeholder: "3591234",
  },
  [CountryCode.BH]: { name: "Bahrain", prefix: "973", placeholder: "36001234" },
  [CountryCode.BD]: {
    name: "Bangladesh",
    prefix: "880",
    placeholder: "01512345678",
  },
  [CountryCode.BB]: {
    name: "Barbados",
    prefix: "1-246",
    placeholder: "2311234",
  },
  [CountryCode.BY]: {
    name: "Belarus",
    prefix: "375",
    placeholder: "291234567",
  },
  [CountryCode.BE]: {
    name: "Belgium",
    prefix: "32",
    placeholder: "0412345678",
  },
  [CountryCode.BZ]: { name: "Belize", prefix: "501", placeholder: "6101234" },
  [CountryCode.BJ]: { name: "Benin", prefix: "229", placeholder: "90123456" },
  [CountryCode.BM]: {
    name: "Bermuda",
    prefix: "1-441",
    placeholder: "3701234",
  },
  [CountryCode.BT]: { name: "Bhutan", prefix: "975", placeholder: "17123456" },
  [CountryCode.BO]: { name: "Bolivia", prefix: "591", placeholder: "70123456" },
  [CountryCode.BA]: {
    name: "Bosnia and Herzegovina",
    prefix: "387",
    placeholder: "61123456",
  },
  [CountryCode.BW]: {
    name: "Botswana",
    prefix: "267",
    placeholder: "71123456",
  },
  [CountryCode.BR]: {
    name: "Brazil",
    prefix: "55",
    placeholder: "11912345678",
  },
  [CountryCode.IO]: {
    name: "British Indian Ocean",
    prefix: "246",
    placeholder: "3801234",
  },
  [CountryCode.VG]: {
    name: "British Virgin Islands",
    prefix: "1-284",
    placeholder: "4401234",
  },
  [CountryCode.BN]: { name: "Brunei", prefix: "673", placeholder: "7712345" },
  [CountryCode.BG]: {
    name: "Bulgaria",
    prefix: "359",
    placeholder: "871234567",
  },
  [CountryCode.BF]: {
    name: "Burkina Faso",
    prefix: "226",
    placeholder: "70123456",
  },
  [CountryCode.BI]: { name: "Burundi", prefix: "257", placeholder: "79123456" },
  [CountryCode.CV]: {
    name: "Cape Verde",
    prefix: "238",
    placeholder: "9912345",
  },
  [CountryCode.KH]: {
    name: "Cambodia",
    prefix: "855",
    placeholder: "012345678",
  },
  [CountryCode.CM]: {
    name: "Cameroon",
    prefix: "237",
    placeholder: "671234567",
  },
  [CountryCode.CA]: {
    name: "Canada",
    prefix: "1",
    placeholder: "1234567890",
    smsEnabled: true,
  },
  [CountryCode.KY]: {
    name: "Cayman Islands",
    prefix: "1-345",
    placeholder: "3221234",
  },
  [CountryCode.CF]: {
    name: "Central African Republic",
    prefix: "236",
    placeholder: "70012345",
  },
  [CountryCode.TD]: { name: "Chad", prefix: "235", placeholder: "63012345" },
  [CountryCode.CL]: { name: "Chile", prefix: "56", placeholder: "961234567" },
  [CountryCode.CN]: { name: "China", prefix: "86", placeholder: "13123456789" },
  [CountryCode.CX]: {
    name: "Christmas Island",
    prefix: "61",
    placeholder: "0412345678",
  },
  [CountryCode.CC]: {
    name: "Cocos [Keeling] Islands",
    prefix: "61",
    placeholder: "89123456",
  },
  [CountryCode.CO]: {
    name: "Colombia",
    prefix: "57",
    placeholder: "3211234567",
  },
  [CountryCode.KM]: { name: "Comoros", prefix: "269", placeholder: "77123456" },
  [CountryCode.CD]: {
    name: "Congo [DRC]",
    prefix: "243",
    placeholder: "991234567",
  },
  [CountryCode.CG]: {
    name: "Congo [Republic]",
    prefix: "242",
    placeholder: "0612345678",
  },
  [CountryCode.CK]: {
    name: "Cook Islands",
    prefix: "682",
    placeholder: "71012",
  },
  [CountryCode.CR]: {
    name: "Costa Rica",
    prefix: "506",
    placeholder: "83101234",
  },
  [CountryCode.CI]: {
    name: "Côte d’Ivoire",
    prefix: "225",
    placeholder: "01123456",
  },
  [CountryCode.HR]: {
    name: "Croatia",
    prefix: "385",
    placeholder: "0912345678",
  },
  [CountryCode.CU]: { name: "Cuba", prefix: "53", placeholder: "51234567" },
  [CountryCode.CY]: { name: "Cyprus", prefix: "357", placeholder: "96123456" },
  [CountryCode.CZ]: {
    name: "Czech Republic",
    prefix: "420",
    placeholder: "601234567",
  },
  [CountryCode.DK]: { name: "Denmark", prefix: "45", placeholder: "20123456" },
  [CountryCode.DJ]: {
    name: "Djibouti",
    prefix: "253",
    placeholder: "77123456",
  },
  [CountryCode.DM]: {
    name: "Dominica",
    prefix: "1-767",
    placeholder: "2251234",
  },
  [CountryCode.DO]: {
    name: "Dominican Republic",
    prefix: "1-809",
    placeholder: "2012345",
  },
  [CountryCode.EC]: {
    name: "Ecuador",
    prefix: "593",
    placeholder: "991234567",
  },
  [CountryCode.EG]: { name: "Egypt", prefix: "20", placeholder: "01012345678" },
  [CountryCode.SV]: {
    name: "El Salvador",
    prefix: "503",
    placeholder: "70123456",
  },
  [CountryCode.GQ]: {
    name: "Equatorial Guinea",
    prefix: "240",
    placeholder: "222123456",
  },
  [CountryCode.ER]: { name: "Eritrea", prefix: "291", placeholder: "7123456" },
  [CountryCode.EE]: { name: "Estonia", prefix: "372", placeholder: "51234567" },
  [CountryCode.SZ]: {
    name: "Eswatini (Swaziland)",
    prefix: "268",
    placeholder: "76123456",
  },
  [CountryCode.ET]: {
    name: "Ethiopia",
    prefix: "251",
    placeholder: "911123456",
  },
  [CountryCode.FK]: {
    name: "Falkland Islands",
    prefix: "500",
    placeholder: "211234",
  },
  [CountryCode.FO]: {
    name: "Faroe Islands",
    prefix: "298",
    placeholder: "211234",
  },
  [CountryCode.FJ]: { name: "Fiji", prefix: "679", placeholder: "7012345" },
  [CountryCode.FI]: {
    name: "Finland",
    prefix: "358",
    placeholder: "0412345678",
  },
  [CountryCode.FR]: {
    name: "France",
    prefix: "33",
    placeholder: "0612345678",
    smsEnabled: true,
  },
  [CountryCode.GF]: {
    name: "French Guiana",
    prefix: "594",
    placeholder: "694201234",
  },
  [CountryCode.PF]: {
    name: "French Polynesia",
    prefix: "689",
    placeholder: "87123456",
  },
  [CountryCode.GA]: { name: "Gabon", prefix: "241", placeholder: "06123456" },
  [CountryCode.GM]: { name: "Gambia", prefix: "220", placeholder: "3012345" },
  [CountryCode.GE]: {
    name: "Georgia",
    prefix: "995",
    placeholder: "555123456",
  },
  [CountryCode.DE]: {
    name: "Germany",
    prefix: "49",
    placeholder: "015123456789",
    smsEnabled: true,
  },
  [CountryCode.GH]: {
    name: "Ghana",
    prefix: "233",
    placeholder: "0241234567",
    smsEnabled: true,
  },
  [CountryCode.GI]: {
    name: "Gibraltar",
    prefix: "350",
    placeholder: "57123456",
  },
  [CountryCode.GR]: { name: "Greece", prefix: "30", placeholder: "6912345678" },
  [CountryCode.GL]: { name: "Greenland", prefix: "299", placeholder: "221234" },
  [CountryCode.GD]: {
    name: "Grenada",
    prefix: "1-473",
    placeholder: "4031234",
  },
  [CountryCode.GP]: {
    name: "Guadeloupe",
    prefix: "590",
    placeholder: "690301234",
  },
  [CountryCode.GU]: { name: "Guam", prefix: "1-671", placeholder: "3001234" },
  [CountryCode.GT]: {
    name: "Guatemala",
    prefix: "502",
    placeholder: "51234567",
  },
  [CountryCode.GN]: { name: "Guinea", prefix: "224", placeholder: "60123456" },
  [CountryCode.GW]: {
    name: "Guinea-Bissau",
    prefix: "245",
    placeholder: "5012345",
  },
  [CountryCode.GY]: { name: "Guyana", prefix: "592", placeholder: "6091234" },
  [CountryCode.HT]: { name: "Haiti", prefix: "509", placeholder: "34123456" },
  [CountryCode.HN]: {
    name: "Honduras",
    prefix: "504",
    placeholder: "91234567",
  },
  [CountryCode.HK]: {
    name: "Hong Kong",
    prefix: "852",
    placeholder: "51234567",
  },
  [CountryCode.HU]: { name: "Hungary", prefix: "36", placeholder: "201234567" },
  [CountryCode.IS]: { name: "Iceland", prefix: "354", placeholder: "6123456" },
  [CountryCode.IN]: {
    name: "India",
    prefix: "91",
    placeholder: "8123456789",
    smsEnabled: true,
  },
  [CountryCode.ID]: {
    name: "Indonesia",
    prefix: "62",
    placeholder: "08123456789",
  },
  [CountryCode.IR]: { name: "Iran", prefix: "98", placeholder: "091212345678" },
  [CountryCode.IQ]: { name: "Iraq", prefix: "964", placeholder: "7912345678" },
  [CountryCode.IE]: {
    name: "Ireland",
    prefix: "353",
    placeholder: "0861234567",
  },
  [CountryCode.IL]: { name: "Israel", prefix: "972", placeholder: "501234567" },
  [CountryCode.IT]: { name: "Italy", prefix: "39", placeholder: "3212345678" },
  [CountryCode.JM]: {
    name: "Jamaica",
    prefix: "1-876",
    placeholder: "2212345",
  },
  [CountryCode.JP]: { name: "Japan", prefix: "81", placeholder: "09012345678" },
  [CountryCode.JO]: {
    name: "Jordan",
    prefix: "962",
    placeholder: "0791234567",
  },
  [CountryCode.KZ]: {
    name: "Kazakhstan",
    prefix: "7",
    placeholder: "7012345678",
  },
  [CountryCode.KE]: {
    name: "Kenya",
    prefix: "254",
    placeholder: "0712345678",
    smsEnabled: true,
  },
  [CountryCode.KI]: { name: "Kiribati", prefix: "686", placeholder: "91234" },
  [CountryCode.KW]: { name: "Kuwait", prefix: "965", placeholder: "50123456" },
  [CountryCode.KG]: {
    name: "Kyrgyzstan",
    prefix: "996",
    placeholder: "700123456",
  },
  [CountryCode.LA]: { name: "Laos", prefix: "856", placeholder: "2021234567" },
  [CountryCode.LV]: { name: "Latvia", prefix: "371", placeholder: "21234567" },
  [CountryCode.LB]: { name: "Lebanon", prefix: "961", placeholder: "71123456" },
  [CountryCode.LS]: { name: "Lesotho", prefix: "266", placeholder: "50123456" },
  [CountryCode.LR]: {
    name: "Liberia",
    prefix: "231",
    placeholder: "770123456",
  },
  [CountryCode.LY]: { name: "Libya", prefix: "218", placeholder: "912345678" },
  [CountryCode.LI]: {
    name: "Liechtenstein",
    prefix: "423",
    placeholder: "661234567",
  },
  [CountryCode.LT]: {
    name: "Lithuania",
    prefix: "370",
    placeholder: "61234567",
  },
  [CountryCode.LU]: {
    name: "Luxembourg",
    prefix: "352",
    placeholder: "621234567",
  },
  [CountryCode.MO]: { name: "Macau", prefix: "853", placeholder: "66123456" },
  [CountryCode.MG]: {
    name: "Madagascar",
    prefix: "261",
    placeholder: "321234567",
  },
  [CountryCode.MW]: { name: "Malawi", prefix: "265", placeholder: "991234567" },
  [CountryCode.MY]: {
    name: "Malaysia",
    prefix: "60",
    placeholder: "0123456789",
  },
  [CountryCode.MV]: { name: "Maldives", prefix: "960", placeholder: "7712345" },
  [CountryCode.ML]: { name: "Mali", prefix: "223", placeholder: "70123456" },
  [CountryCode.MT]: { name: "Malta", prefix: "356", placeholder: "99123456" },
  [CountryCode.MH]: {
    name: "Marshall Islands",
    prefix: "692",
    placeholder: "2351234",
  },
  [CountryCode.MQ]: {
    name: "Martinique",
    prefix: "596",
    placeholder: "696201234",
  },
  [CountryCode.MR]: {
    name: "Mauritania",
    prefix: "222",
    placeholder: "22123456",
  },
  [CountryCode.MU]: {
    name: "Mauritius",
    prefix: "230",
    placeholder: "5712345",
  },
  [CountryCode.YT]: {
    name: "Mayotte",
    prefix: "262",
    placeholder: "639012345",
  },
  [CountryCode.MX]: { name: "Mexico", prefix: "52", placeholder: "5512345678" },
  [CountryCode.FM]: {
    name: "Micronesia",
    prefix: "691",
    placeholder: "3501234",
  },
  [CountryCode.MD]: { name: "Moldova", prefix: "373", placeholder: "62123456" },
  [CountryCode.MC]: { name: "Monaco", prefix: "377", placeholder: "612345678" },
  [CountryCode.MN]: {
    name: "Mongolia",
    prefix: "976",
    placeholder: "88123456",
  },
  [CountryCode.ME]: {
    name: "Montenegro",
    prefix: "382",
    placeholder: "67123456",
  },
  [CountryCode.MS]: {
    name: "Montserrat",
    prefix: "1-664",
    placeholder: "4923456",
  },
  [CountryCode.MA]: {
    name: "Morocco",
    prefix: "212",
    placeholder: "0612345678",
  },
  [CountryCode.MZ]: {
    name: "Mozambique",
    prefix: "258",
    placeholder: "821234567",
  },
  [CountryCode.NA]: {
    name: "Namibia",
    prefix: "264",
    placeholder: "811234567",
  },
  [CountryCode.NR]: { name: "Nauru", prefix: "674", placeholder: "5551234" },
  [CountryCode.NP]: { name: "Nepal", prefix: "977", placeholder: "9812345678" },
  [CountryCode.NL]: {
    name: "Netherlands",
    prefix: "31",
    placeholder: "0612345678",
  },
  [CountryCode.NC]: {
    name: "New Caledonia",
    prefix: "687",
    placeholder: "751234",
  },
  [CountryCode.NZ]: {
    name: "New Zealand",
    prefix: "64",
    placeholder: "0211234567",
  },
  [CountryCode.NI]: {
    name: "Nicaragua",
    prefix: "505",
    placeholder: "81234567",
  },
  [CountryCode.NE]: { name: "Niger", prefix: "227", placeholder: "93123456" },
  [CountryCode.NG]: {
    priority: 100,
    name: "Nigeria",
    prefix: "234",
    placeholder: "8012345678",
    smsEnabled: true,
    allowMatchByIP: true,
  },
  [CountryCode.NU]: { name: "Niue", prefix: "683", placeholder: "1234" },
  [CountryCode.NF]: {
    name: "Norfolk Island",
    prefix: "672",
    placeholder: "381234",
  },
  [CountryCode.KP]: {
    name: "North Korea",
    prefix: "850",
    placeholder: "1912345678",
  },
  [CountryCode.MK]: {
    name: "North Macedonia",
    prefix: "389",
    placeholder: "71234567",
  },
  [CountryCode.MP]: {
    name: "Northern Mariana Islands",
    prefix: "1-670",
    placeholder: "2345678",
  },
  [CountryCode.NO]: { name: "Norway", prefix: "47", placeholder: "412345678" },
  [CountryCode.OM]: { name: "Oman", prefix: "968", placeholder: "92123456" },
  [CountryCode.PK]: {
    name: "Pakistan",
    prefix: "92",
    placeholder: "03123456789",
  },
  [CountryCode.PW]: { name: "Palau", prefix: "680", placeholder: "6201234" },
  [CountryCode.PS]: {
    name: "Palestine",
    prefix: "970",
    placeholder: "591234567",
  },
  [CountryCode.PA]: { name: "Panama", prefix: "507", placeholder: "61234567" },
  [CountryCode.PG]: {
    name: "Papua New Guinea",
    prefix: "675",
    placeholder: "70123456",
  },
  [CountryCode.PY]: {
    name: "Paraguay",
    prefix: "595",
    placeholder: "961234567",
  },
  [CountryCode.PE]: { name: "Peru", prefix: "51", placeholder: "912345678" },
  [CountryCode.PH]: {
    name: "Philippines",
    prefix: "63",
    placeholder: "09123456789",
  },
  [CountryCode.PN]: {
    name: "Pitcairn Islands",
    prefix: "64",
    placeholder: "9012345678",
  },
  [CountryCode.PL]: { name: "Poland", prefix: "48", placeholder: "0912345678" },
  [CountryCode.PT]: {
    name: "Portugal",
    prefix: "351",
    placeholder: "912345678",
  },
  [CountryCode.PR]: {
    name: "Puerto Rico",
    prefix: "1-787",
    placeholder: "2012345",
  },
  [CountryCode.QA]: { name: "Qatar", prefix: "974", placeholder: "50123456" },
  [CountryCode.RE]: {
    name: "Reunion",
    prefix: "262",
    placeholder: "692012345",
  },
  [CountryCode.RO]: { name: "Romania", prefix: "40", placeholder: "712345678" },
  [CountryCode.RU]: { name: "Russia", prefix: "7", placeholder: "9123456789" },
  [CountryCode.RW]: { name: "Rwanda", prefix: "250", placeholder: "781234567" },
  [CountryCode.BL]: {
    name: "Saint Barthélemy",
    prefix: "590",
    placeholder: "690123456",
  },
  [CountryCode.SH]: {
    name: "Saint Helena",
    prefix: "290",
    placeholder: "22134",
  },
  [CountryCode.KN]: {
    name: "Saint Kitts and Nevis",
    prefix: "1-869",
    placeholder: "6623456",
  },
  [CountryCode.LC]: {
    name: "Saint Lucia",
    prefix: "1-758",
    placeholder: "2841234",
  },
  [CountryCode.MF]: {
    name: "Saint Martin",
    prefix: "590",
    placeholder: "690123456",
  },
  [CountryCode.PM]: {
    name: "Saint Pierre and Miquelon",
    prefix: "508",
    placeholder: "551234",
  },
  [CountryCode.VC]: {
    name: "St. Vincent and Grenadines",
    prefix: "1-784",
    placeholder: "4301234",
  },
  [CountryCode.WS]: { name: "Samoa", prefix: "685", placeholder: "721234" },
  [CountryCode.SM]: {
    name: "San Marino",
    prefix: "378",
    placeholder: "66666666",
  },
  [CountryCode.ST]: {
    name: "São Tomé and Príncipe",
    prefix: "239",
    placeholder: "9012345",
  },
  [CountryCode.SA]: {
    name: "Saudi Arabia",
    prefix: "966",
    placeholder: "0512345678",
  },
  [CountryCode.SN]: {
    name: "Senegal",
    prefix: "221",
    placeholder: "701234567",
  },
  [CountryCode.RS]: { name: "Serbia", prefix: "381", placeholder: "601234567" },
  [CountryCode.SC]: {
    name: "Seychelles",
    prefix: "248",
    placeholder: "2510123",
  },
  [CountryCode.SL]: {
    name: "Sierra Leone",
    prefix: "232",
    placeholder: "25123456",
  },
  [CountryCode.SG]: {
    name: "Singapore",
    prefix: "65",
    placeholder: "81234567",
  },
  [CountryCode.SX]: {
    name: "Sint Maarten",
    prefix: "1-721",
    placeholder: "5801234",
  },
  [CountryCode.SK]: {
    name: "Slovakia",
    prefix: "421",
    placeholder: "912345678",
  },
  [CountryCode.SI]: {
    name: "Slovenia",
    prefix: "386",
    placeholder: "31234567",
  },
  [CountryCode.SB]: {
    name: "Solomon Islands",
    prefix: "677",
    placeholder: "7421234",
  },
  [CountryCode.SO]: { name: "Somalia", prefix: "252", placeholder: "90123456" },
  [CountryCode.ZA]: {
    name: "South Africa",
    prefix: "27",
    placeholder: "0712345678",
  },
  [CountryCode.KR]: {
    name: "South Korea",
    prefix: "82",
    placeholder: "01012345678",
  },
  [CountryCode.SS]: {
    name: "South Sudan",
    prefix: "211",
    placeholder: "912345678",
  },
  [CountryCode.ES]: { name: "Spain", prefix: "34", placeholder: "612345678" },
  [CountryCode.LK]: {
    name: "Sri Lanka",
    prefix: "94",
    placeholder: "712345678",
  },
  [CountryCode.SD]: { name: "Sudan", prefix: "249", placeholder: "912345678" },
  [CountryCode.SR]: { name: "Suriname", prefix: "597", placeholder: "7123456" },
  [CountryCode.SE]: { name: "Sweden", prefix: "46", placeholder: "0701234567" },
  [CountryCode.CH]: {
    name: "Switzerland",
    prefix: "41",
    placeholder: "0712345678",
  },
  [CountryCode.SY]: { name: "Syria", prefix: "963", placeholder: "912345678" },
  [CountryCode.TW]: { name: "Taiwan", prefix: "886", placeholder: "912345678" },
  [CountryCode.TJ]: {
    name: "Tajikistan",
    prefix: "992",
    placeholder: "372123456",
  },
  [CountryCode.TZ]: {
    name: "Tanzania",
    prefix: "255",
    placeholder: "0621234567",
    smsEnabled: true,
  },
  [CountryCode.TH]: {
    name: "Thailand",
    prefix: "66",
    placeholder: "0812345678",
  },
  [CountryCode.TL]: {
    name: "Timor-Leste",
    prefix: "670",
    placeholder: "77212345",
  },
  [CountryCode.TG]: { name: "Togo", prefix: "228", placeholder: "90123456" },
  [CountryCode.TK]: { name: "Tokelau", prefix: "690", placeholder: "301234" },
  [CountryCode.TO]: { name: "Tonga", prefix: "676", placeholder: "7712345" },
  [CountryCode.TT]: {
    name: "Trinidad and Tobago",
    prefix: "1-868",
    placeholder: "6201234",
  },
  [CountryCode.TN]: { name: "Tunisia", prefix: "216", placeholder: "20123456" },
  [CountryCode.TR]: {
    name: "Turkey",
    prefix: "90",
    placeholder: "05001234567",
  },
  [CountryCode.TM]: {
    name: "Turkmenistan",
    prefix: "993",
    placeholder: "66123456",
  },
  [CountryCode.TV]: { name: "Tuvalu", prefix: "688", placeholder: "901234" },
  [CountryCode.UG]: {
    priority: 99,
    name: "Uganda",
    prefix: "256",
    placeholder: "0712345678",
    smsEnabled: true,
    allowMatchByIP: true,
  },
  [CountryCode.UA]: {
    name: "Ukraine",
    prefix: "380",
    placeholder: "0912345678",
  },
  [CountryCode.AE]: {
    name: "United Arab Emirates",
    prefix: "971",
    placeholder: "0501234567",
  },
  [CountryCode.GB]: {
    priority: 9,
    name: "United Kingdom",
    prefix: "44",
    placeholder: "07400123456",
    // https://www.twilio.com/en-us/guidelines/gb/sms
    // SMS disallowed from international long codes
    smsEnabled: false,
  },
  [CountryCode.US]: {
    priority: 10,
    name: "United States",
    prefix: "1",
    placeholder: "1234567890",
    smsEnabled: true,
  },
  [CountryCode.UY]: { name: "Uruguay", prefix: "598", placeholder: "94123456" },
  [CountryCode.UZ]: {
    name: "Uzbekistan",
    prefix: "998",
    placeholder: "912345678",
  },
  [CountryCode.VU]: { name: "Vanuatu", prefix: "678", placeholder: "5912345" },
  [CountryCode.VA]: {
    name: "Vatican City",
    prefix: "379",
    placeholder: "001234",
  },
  [CountryCode.VE]: {
    name: "Venezuela",
    prefix: "58",
    placeholder: "4123456789",
  },
  [CountryCode.VN]: {
    name: "Vietnam",
    prefix: "84",
    placeholder: "0912345678",
  },
  [CountryCode.WF]: {
    name: "Wallis and Futuna",
    prefix: "681",
    placeholder: "501234",
  },
  [CountryCode.EH]: {
    name: "Western Sahara",
    prefix: "212",
    placeholder: "612345678",
  },
  [CountryCode.YE]: { name: "Yemen", prefix: "967", placeholder: "711234567" },
  [CountryCode.ZM]: {
    priority: 98,
    name: "Zambia",
    prefix: "260",
    placeholder: "0971234567",
    smsEnabled: true,
    allowMatchByIP: true,
  },
  [CountryCode.ZW]: {
    name: "Zimbabwe",
    prefix: "263",
    placeholder: "0712345678",
  },
};

// get the subset that have a priority, ordered by priority desc
export const priorityDetails = (Object.keys(countryDetails) as CountryCode[])
  .filter((key) => countryDetails[key].priority !== undefined)
  .sort(
    (a, b) =>
      (countryDetails[b].priority || 0) - (countryDetails[a].priority || 0),
  )
  .map((key) => ({ code: key, ...countryDetails[key] }));

export const smsDetails = (Object.keys(countryDetails) as CountryCode[])
  .filter((key) => countryDetails[key].smsEnabled === true)
  .sort(
    (a, b) =>
      (countryDetails[b].priority || 0) - (countryDetails[a].priority || 0),
  )
  .map((key) => ({ code: key, ...countryDetails[key] }));
