import type { ChangeEvent } from "react";

import Select from "@/components/forms/Select";

import {
  countryDetails,
  priorityDetails,
  smsDetails,
} from "@/hl-common/types/CountryDetails";
import type { CountryCode } from "@/hl-common/types/api/CountryCode";
import { auth_channel } from "@/hl-common/types/api/PrismaEnums";

const smsOptions = (
  <>
    {smsDetails.map(({ name, prefix, code }) => {
      return (
        <option key={code} value={code}>
          {name} (+{prefix})
        </option>
      );
    })}
  </>
);

const whatsAppOptions = (
  <>
    {priorityDetails.map(({ name, prefix, code }) => {
      return (
        <option key={code} value={code}>
          {name} (+{prefix})
        </option>
      );
    })}
    {/* divider */}
    <option disabled>---</option>
    {Object.keys(countryDetails).map((code) => {
      const { name, prefix } = countryDetails[code as CountryCode];
      return (
        <option key={name} value={code}>
          {name} (+{prefix})
        </option>
      );
    })}
  </>
);

// acts mostly like normal Select, but also stores the selected country code in a cookie
export const CountrySelect = ({
  name,
  value,
  authChannel,
  handleInputChange,
}: {
  name: string;
  value: string;
  authChannel: auth_channel;
  handleInputChange: (event: ChangeEvent<HTMLSelectElement>) => void;
}) => {
  const options =
    authChannel === auth_channel.SMS ? smsOptions : whatsAppOptions;

  return (
    <Select
      className="mb-4 mx-auto"
      shrink
      name={name}
      value={value}
      onChange={(e) => {
        handleInputChange(e);
        storeCountryCode(e.target.value as CountryCode);
      }}
    >
      {options}
    </Select>
  );
};

const storeCountryCode = (code: CountryCode) => {
  try {
    if (document) {
      document.cookie = `countryCode=${code}; SameSite=Lax; path=/login; max-age=31536000;`;
    }
  } catch (error) {
    console.error("Failed to store country code in cookie", error);
  }
};
