"use client";

import classNames from "classnames";
import { useEffect, useState } from "react";

// horizontal bar that fills up a "progress bar" style thing as the countdown timer ticks down
// Note: you might be tempted to paramterize the duration of the countdown, but that doesn't play nicely with tailwind's transition duration thing
export const Countdown = ({ callback }: { callback: () => void }) => {
  const [className, setClassName] = useState("w-0");

  // change width to full after a short delay
  // The actual width will then slowly change, thanks to CSS transitions
  useEffect(() => {
    const lessMotion =
      typeof matchMedia === "function" &&
      matchMedia("(prefers-reduced-motion)").matches;

    if (!lessMotion) {
      setClassName("w-full");
    }
  }, []);

  // do an action after the timer
  useEffect(() => {
    const timer = setTimeout(callback, 4700); // intentionally 300ms earlier than the css transition which completes fast for some reason
    return () => clearTimeout(timer);
  }, [callback]);

  return (
    <div
      className={classNames(
        "absolute top-0 left-0 h-1 z-10 bg-blue-200 rounded ease-linear duration-[5000ms]",
        className,
      )}
    />
  );
};
