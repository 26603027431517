import { useEffect, useState } from "react";

export const useCookiesEnabled = () => {
  const [cookiesEnabled, setCookiesEnabled] = useState(true);
  useEffect(() => {
    // window is only available on client, useEffect will only run on client
    setCookiesEnabled(window?.navigator?.cookieEnabled);
  }, []);
  return cookiesEnabled;
};
