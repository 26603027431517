import classNames from "classnames";

export const Field = ({
  label,
  description,
  required,
  children,
  className,
}: {
  label?: string;
  description?: string;
  required?: boolean;
  className?: string;
  children: React.ReactNode;
}) => {
  if (!label) {
    return children;
  }
  return (
    <div className={classNames("block", className)}>
      <label className="mb-1 text-gray-500 block">
        {label}

        {required && <span className="text-red-500 ml-1">*</span>}
      </label>
      {children}
      {description && (
        <p className="text-xs text-gray-500 mb-4">{description}</p>
      )}
    </div>
  );
};
