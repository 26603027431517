import {
  type Dispatch,
  type SetStateAction,
  useCallback,
  useState,
} from "react";

// The change handlers in here use an input's name to modify field values at
// that name. They do not support dot notation for modifying nested fields. We
// used to do that, but depending on lodash's `_.get` and `_.set` had a
// significant impact on bundle size.
function get(object: any, path: string, defaultValue?: any) {
  const result = object[path];
  return result !== undefined ? result : defaultValue;
}

function set(object: any, path: string, value: any) {
  object[path] = value;
  return object;
}

export const useForm = <T extends Record<string, any>>(
  initialState: T,
  onSubmit: (data: T, setFields: Dispatch<SetStateAction<T>>) => void,
) => {
  const [fields, setFields] = useState(initialState);

  const handleInputChange = useCallback(
    (
      e: React.ChangeEvent<
        HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
      >,
    ) => {
      if (e.target.type === "checkbox") {
        setFields(
          set(
            { ...fields },
            e.target.name,
            (e as React.ChangeEvent<HTMLInputElement>).target.checked,
          ),
        );
      } else {
        setFields(set({ ...fields }, e.target.name, e.target.value));
      }
    },
    [fields],
  );

  const handleInputChangeArray = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      let arr = get({ ...fields }, e.target.name, [] as any[]);
      if (!Array.isArray(arr)) {
        const type = typeof arr;
        throw new TypeError(
          `expected array in handleInputChangeArray, got: ${type}`,
        );
      }
      if (e.target.checked) {
        arr = [...arr, e.target.value];
      } else {
        arr = arr.filter((value: string) => value !== e.target.value);
      }

      setFields(set({ ...fields }, e.target.name, arr));
    },
    [fields],
  );

  const handleInputChangeNumber = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      const newValue =
        e.target.value === "" ? undefined : Number(e.target.value);
      setFields(set({ ...fields }, e.target.name, newValue));
    },
    [fields],
  );

  const handleInputChangeArrayNumber = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      let arr = get({ ...fields }, e.target.name, [] as any[]);
      if (!Array.isArray(arr)) {
        const type = typeof arr;
        throw new TypeError(
          `expected array in handleInputChangeArray, got: ${type}`,
        );
      }
      if (e.target.checked) {
        arr = [...arr, Number(e.target.value)];
      } else {
        arr = arr.filter((value: number) => value !== Number(e.target.value));
      }

      setFields(set({ ...fields }, e.target.name, arr));
    },
    [fields],
  );

  const handleSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      onSubmit?.(fields, setFields);
    },
    [fields, onSubmit],
  );

  return {
    fields,
    setFields,
    handleInputChange,
    handleInputChangeArray,
    handleInputChangeNumber,
    handleInputChangeArrayNumber,
    handleSubmit,
  };
};
